import { ArrowLeftCircleIcon, ArrowRightCircleIcon } from "@heroicons/react/24/outline";
import React, { useState, useEffect } from "react";

const Pagination = ({ pageNo, pageSize, totalRecords, onPageChange, onPageSizeChange, length }) => {


  const totalPages = Math.ceil(totalRecords / pageSize);
  const [currentPage, setCurrentPage] = useState(pageNo);

  //onsole.log(Math.ceil(totalRecords / pageSize));

  const handlePageChange = (page) => {
    setCurrentPage(page);
    onPageChange(page);
  };

  useEffect(() => {
    // setCurrentPage(1); // Reset the current page when pageSize changes
  }, [pageSize]);



  const handleFirstPage = () => {
    handlePageChange(1); // Go to the first page
  };

  const handleLastPage = () => {
    handlePageChange(totalPages); // Go to the last page
  };


  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5; // Set the maximum number of page numbers to show
    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);

    // Add the current page number
    pageNumbers.push(currentPage);

    // Add the page numbers before the current page
    for (let i = 1; i <= halfMaxPagesToShow; i++) {
      if (currentPage - i > 0) {
        pageNumbers.unshift(currentPage - i);
      }
    }

    // Add the page numbers after the current page
    for (let i = 1; i <= halfMaxPagesToShow; i++) {
      if (currentPage + i <= totalPages) {
        pageNumbers.push(currentPage + i);
      }
    }

    // Add the first and last page numbers if they're not already included
    if (!pageNumbers.includes(1)) {
      pageNumbers.unshift(1);
    }
    if (!pageNumbers.includes(totalPages)) {
      pageNumbers.push(totalPages);
    }

    return pageNumbers;
  };

  const handlePageSizeChange = (e) => {
    const newSize = parseInt(e.target.value);
    onPageSizeChange(newSize);
    setCurrentPage(1); // Reset to the first page when changing page size
  };


  return (
    <>
      <div className="table-footer">
        <div className="page-info">
          {/* <label><span>{length}</span> of {totalRecords}</label> */}
          <label><span>{totalRecords}</span> items in {totalPages} pages</label>
          <div className="number-select">
            <label>Records</label>
            <select
              maxLength={3}
              value={pageSize}
              onChange={handlePageSizeChange}
              className="form-control"
            >
              <option value={10}>10</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={500}>500</option>
              <option value={1000}>1000</option>
            </select>
          </div>
        </div>
        <div className="bs-pagination-wrap">
          <div className={`arrows left ${currentPage === 1 ? "disabled" : ""}`}>
            <button
              className="page-link"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <ArrowLeftCircleIcon />
            </button>
          </div>
          <ul className="bs-pagination">

            {/* Render the first page number if it's not already shown */}
            {getPageNumbers()[0] !== 1 && (
              <li className="page-item disabled">
                <button className="page-link">...</button>
              </li>
            )}
            {/* Render the page numbers */}


            {getPageNumbers().map((pageNumber) => (
              <li
                key={pageNumber}
                className={`page-item ${pageNumber === currentPage ? "active" : ""
                  }`}
              >
                <button
                  className="page-link"
                  onClick={() => handlePageChange(pageNumber)}
                >
                  {pageNumber}
                </button>
              </li>
            ))}
            {/* Render the last page number if it's not already shown */}
            {getPageNumbers().slice(-1)[0] !== totalPages && (
              <li className="page-item disabled">
                <button className="page-link">...</button>
              </li>
            )}

          </ul>
          <div className={`arrows right ${currentPage === totalPages ? "disabled" : ""}`}>
            <button
              className="page-link"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <ArrowRightCircleIcon />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pagination;
