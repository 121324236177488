import React, { useEffect, useState } from 'react';
import logo from '../../../assets/images/logo/logo-icon.svg';
import { NavLink } from "react-router-dom";

function Header(props) {

    const [isScrolled, setIsScrolled] = useState(false);
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll = () => {
        const scrollThreshold = 150;
        const shouldAddClass = window.scrollY > scrollThreshold;
        setIsScrolled(shouldAddClass);
    };


    return (
        <>
            <nav className={`navbar navbar-expand-lg navbar-main ${isScrolled ? 'sticky' : ''}`} data-bs-theme="dark">
                <div className="container">
                    <NavLink className="navbar-brand" to="/">
                        <img src={logo} />
                    </NavLink>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">


                            <li className="nav-item">
                                <NavLink className="nav-link" onClick={() => window.scrollTo(0, 0)} to="/">Home</NavLink>
                            </li>

                            <li className="nav-item">
                                <NavLink className="nav-link" onClick={() => window.scrollTo(0, 0)} to="/about">About Us</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" onClick={() => window.scrollTo(0, 0)} to="/media">Media</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" onClick={() => window.scrollTo(0, 0)} to="/foundation">Foundation</NavLink>
                            </li>

                            <li className="nav-item">
                                <NavLink className="nav-link" onClick={() => window.scrollTo(0, 0)} to="/contactUs">Our Branches</NavLink>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link button button-primary" target='_blank' href="https://partner.mag.holdings/">Login</a>
                            </li>


                        </ul>

                    </div>
                </div>
            </nav>
        </>
    );
}

export default Header;