import React, { useState, useEffect } from 'react';

import logofooter from '../../../assets/images/logo/magholding.svg';
import { Link } from 'react-router-dom';

function Footer(props) {

    return (
        <>
            <footer className="footer-section">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9 col-xs-12 ">
                            <Link onClick={() => window.scrollTo(0, 0)} to="/">
                                <img style={{ width: "80px" }} src={logofooter} alt="" />
                            </Link>
                            <ul className='footer-contact'>
                                <li>
                                    <i class="ri-map-pin-line"></i>
                                    <span>Pakistan Head Office: Rauf Tower First Floor, Main Gt Road, Jinnah Blvd west, DHA Phase 2, Islamabad.
                                    </span>
                                </li>
                                <li>
                                    <i class="ri-phone-line"></i>
                                    <span>+92 318 333 4 555 UAN</span>
                                </li>
                                <li>
                                    <i class="ri-mail-line"></i>
                                    <span>connect@mag.holdings</span>
                                </li>
                            </ul>

                            <ul className="footer-links1">
                                <li><Link onClick={() => window.scrollTo(0, 0)} to="/home">Home</Link></li>
                                <li><Link onClick={() => window.scrollTo(0, 0)} to="/about">About Us</Link></li>
                                <li><Link onClick={() => window.scrollTo(0, 0)} to="/media">Media</Link></li>
                                <li><Link onClick={() => window.scrollTo(0, 0)} to="/foundation">Foundation</Link></li>
                                <li><Link onClick={() => window.scrollTo(0, 0)} to="/contactUs">Contact Us</Link></li>
                                <li><a onClick={() => window.scrollTo(0, 0)} target='_blank' href="https://partner.mag.holdings/#">Login</a></li>
                            </ul>
                        </div>
                        <div className="col-sm-3 col-xs-12 text-right">
                            <h4>Follow us</h4>
                            <ul className="socialIcons">
                                <li><Link onClick={() => window.scrollTo(0, 0)} to="https://www.facebook.com/MagVenturespk/" target="_blank"><i className="ri-facebook-fill"></i></Link></li>
                                <li><Link onClick={() => window.scrollTo(0, 0)} to="https://twitter.com/MagVenturespk" target="_blank"><i className="ri-twitter-x-fill"></i></Link></li>
                                <li><Link onClick={() => window.scrollTo(0, 0)} to="https://www.instagram.com/magventurespk/" target="_blank"><i className="ri-instagram-fill"></i></Link></li>
                                <li><Link onClick={() => window.scrollTo(0, 0)} to="https://www.youtube.com/@MAGVenturespk" target="_blank"><i className="ri-youtube-fill"></i></Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="copyright">
                        <div className="row">
                            <div className="col-md-6">
                                <p>© Copyright 2024 - MAG Ventures</p>
                            </div>
                            <div className="col-md-6 text-right">
                                <ul className="footer-links">
                                    <li><Link onClick={() => window.scrollTo(0, 0)} to="/en/privacy-policy">Privacy Policy</Link></li>
                                    <li><Link onClick={() => window.scrollTo(0, 0)} to="/en/terms-of-service/">Terms of Service</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;
